<template>
  <v-dialog
    v-model="isVisible"
    :persistent="isPersistent"
    :width="width"
    :height="height"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :min-width="minWidth"
    :min-height="minHeight"
  >
    <template
      v-if="!$scopedSlots.activator && !isExternalActivator"
      v-slot:activator="{ on, attrs }"
    >
      <v-btn
        class="mx-2 text__capitalize"
        v-bind="attrs"
        v-on="on"
        :cols="buttonOpen.cols"
        outlined
        :color="buttonOpen.color"
      >
        {{ buttonOpen.text }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="py-2 px-4">
        <v-spacer></v-spacer>
        <slot name="dialogHeader" v-if="isClosed"> </slot>
        <div v-else>
          <v-btn
            color="red"
            icon
            @click="
              isVisible = false;
              $emit('onDialogClose');
            "
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <!-- <slot v-if="!mode" name="dialogContent"> </slot> -->
      <v-card-text class="py-10 text-center" style="line-height: 2" v-if="mode == 'delete'">
        Apakah kamu yakin akan menghapus
        <span class="red--text font-weight-bold" style="font-size: 1rem">
          {{ modeDeleteTextDetail }}
        </span>
        ?
      </v-card-text>

      <v-card-text
        class="text-center justify-center flex-wrap d-flex align-center"
        style="min-height: 100px; color: #505050; opacity: 0.8; font-size: 1rem"
        v-else-if="mode == 'logout'"
      >
        Apakah kamu yakin akan keluar?
      </v-card-text>

      <v-card-text
        class="pa-10"
        style="font-size: 1rem"
        v-else-if="mode == 'delete_image'"
      >
        Apakah kamu yakin akan menghapus gambar?
      </v-card-text>

      <v-card-text
        class="pa-10"
        style="font-size: 1rem"
        v-else-if="mode == 'toggle_publisher'"
      >
        <slot name="cardContent"> </slot>
      </v-card-text>

      <v-card-text
        class="pa-10"
        style="font-size: 1rem"
        v-else-if="mode == 'change'"
      >
        Apakah kamu yakin akan mengganti {{ modeChangeTextDetailBefore }}
        <span class="black--text font-weight-bold" style="font-size: 1rem">
          {{ modeChangeTextDetail }}
        </span>
        {{ modeChangeTextDetailAfter }}?
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            :loading="loading.button"
            v-if="mode == 'delete'"
            color="#FF2727"
            class="white--text text-capitalize"
            @click="$emit('onDelete')"
          >
          <v-icon left>mdi-delete</v-icon>
            Ya,Hapus 
          </v-btn>
          <v-btn
            v-if="mode == 'delete'"
              color="red"
              :loading="loading.button"
              outlined
              @click="isVisible = false"
              class="text-capitalize mx-2"
            >
              Batal
            </v-btn>
          <v-btn
            :loading="loading.button"
            v-if="mode == 'change'"
            color="#F39034"
            width="100"
            class="white--text mb-6 mr-3"
            @click="$emit('onChange')"
          >
            Ya
          </v-btn>

          <slot v-else-if="mode == 'delete_image'" name="dialogButton"> </slot>

          <slot v-else-if="mode == 'logout'" name="dialogButton"> </slot>

          <slot v-else-if="mode == 'toggle_publisher'" name="dialogButton">
          </slot>

          <slot v-if="!mode" name="dialogButton"> </slot>

          <v-btn
            :loading="loading.button"
            v-if="!mode"
            color="red darken-1"
            text
            @click="isVisible = false"
          >
            Tutup
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isPersistent: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExternalActivator: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
      default: "unset",
    },
    height: {
      type: String,
      required: false,
      default: "unset",
    },
    maxWidth: {
      type: String,
      required: false,
      default: "unset",
    },
    maxHeight: {
      type: String,
      required: false,
      default: "unset",
    },
    minWidth: {
      type: String,
      required: false,
      default: "unset",
    },
    minHeight: {
      type: String,
      required: false,
      default: "unset",
    },
    isClosed: {
      type: Boolean,
      required: false,
    },
    mode: {
      type: String,
      required: false,
      default: "",
    },
    modeDeleteTextDetail: {
      type: String,
      required: false,
      default: "",
    },
    modeChangeTextDetailBefore: {
      type: String,
      required: false,
      default: "",
    },
    modeChangeTextDetail: {
      type: String,
      required: false,
      default: "",
    },
    modeChangeTextDetailAfter: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["onDelete", "onChange", "onDialogClose"],
  data: () => ({
    isVisible: false,
    delete_note: "",
    buttonOpen: {
      color: "",
      text: "",
      cols: 12,
    },
    loading: {
      button: false,
    },
  }),
  methods: {
    checkMode() {
      switch (this.mode) {
        case "delete":
          this.buttonOpen.color = "red";
          this.buttonOpen.text = "Hapus";
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    this.checkMode();
  },
};
</script>

<style lang="scss" scoped></style>
